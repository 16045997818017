import api from './api'
import TokenService from './token.service'
import UtilsService from './utils.service';

class AuthService {
  async login({ email, password }) {
    var data = {
        client_id: 2,
        client_secret: "lFNK11weWnJBrOFT6caNuM4O3Sd3zDm5nqXpb3oO",
        // client_secret: "lXiz9wGikIVNhXjhAbqlnanshc687ospWBohoCr1",
        
        grant_type: "password",
        username: email,
        scope: "*",
        password: password,
    };
    
    let token_res = await api.post("/oauth/token", data).then((response) => {
      if (response.data.access_token) {
        TokenService.setUser(response.data) 

        UtilsService.getAuthUser().then((respond) => { 
            if (respond.data.data) {
                let user = JSON.parse(localStorage.getItem('user'))
                user.id = respond.data.data.id
                user.email = respond.data.data.email
                TokenService.setUser(user)
            }
           
        });

      }
      return response.data
    });
    return token_res
  }

  async logout() {
    await TokenService.removeUser()
  }

}

export default new AuthService()

