import api from "./api";

class UtilsService {
	/* innovative */
	lock_edit(user_id, user_token, transaction_id, cl, deactivate = false) {
		return api.post(
			`/lock-edit?user_id=${user_id}&user_token=${user_token}&transaction_id=${transaction_id}&cl=${cl}&deactivate=${deactivate}`
		);
	}

	uniqdashboard(cl_level, client_id, tracking_id) {
		return api.get(
			`/dashboard?cl_id=${cl_level}&client_id=${client_id}&file_tracking=${tracking_id}`
		);
	}
	getFileValidationProgress() {
		return api.get(`/validation-status`);
	}
	Totals() {
		return api.get(`/dashboard-per-client`);
	}
	getStates() {
		return api.get("/states");
	}
	getAgencies() {
		return api.get("/transponder-agencies");
	}
	getOrders() {
		return api.get("/orders");
	}
	updateOrder(id, data) {
		return api.post(`/orders/${id}?_method=put`, data);
	}
	addOrder(data) {
		return api.post("/orders", data);
	}
	getClientAgencies(filter) {
		return api.get("/client-agency", { params: filter });
	}
	getClients() {
		return api.get("/clients");
	}
	getCarriers(filter) {
		return api.get("/clients", { params: filter });
	}
	getClientsLogs() {
		return api.get("/client-logs");
	}
	getClientsAdds() {
		return api.get("/vehicles?draft=1&client_id");
	}
	getClientsUploads() {
		return api.get("/client-uploads");
	}
	deleteClientsUploads(vehicle_id) {
		return api.delete(`/client-uploads/${vehicle_id}`);
	}
	getLpTypes() {
		return api.get("/report-types");
	}
	getInventoryStatus() {
		return api.get("/inventory-status");
	}
	getTransponderStatus() {
		return api.get("/transponder-status");
	}

	getColor() {
		return api.get(`/transponder-colors`);
	}
	getTypes() {
		return api.get(`/transpondertypes`);
	}
	getDepartments(client) {
		return api.get(`/departments?client_id=${client}`);
	}
	getSubDepartments({ dept }) {
		return api.get("/sub-departments", null, { dept });
	}
	getSubsidiaries({ client }) {
		return api.get("/subsidiaries", null, { client });
	}
	getAuthUser() {
		return api.get(`/active-user`);
	}
	async getOverviewDept(client) {
		return await api.get(`/default-department/${client}`);
	}
	getclientdetails(clientId) {
		return api.get(`/clients/${clientId}`);
	}
	getDept(deptId) {
		return api.get(`/departments/${deptId}`);
	}

	getEmployees() {
		return api.get("/employees");
	}

	//transactions
	AddExcelTransaction(data) {
		return api.post("/excel-dumps", data);
	}
	getExcelDetails() {
		return api.get(`/excel-dumps`);
	}
	getAccounts() {
		return api.get(`/accounts-balances`);
	}

	//logs
	GetLogs(user_id, draw, length, start, order, pagedir) {
		return api.get(
			`/logs?user_id=${user_id}&draw=${draw}&length=${length}&start=${start}&order=${order}&pagedir=${pagedir}`
		);
	}
	Getrules(user_id, draw, length, start, order, pagedir) {
		return api.get(
			`/business-rules?user_id=${user_id}&draw=${draw}&length=${length}&start=${start}&order=${order}&pagedir=${pagedir}`
		);
	}
	GetClientRules(client_id, user_id) {
		return api.get(`/business-rules/${client_id}?user_id=${user_id}`);
	}
	addRules(user_id, data) {
		return api.post(`/business-rules?user_id=${user_id}`, data);
	}
	UpdateRules(client_id, user_id, dataE) {
		return api.post(
			`/business-rules/${client_id}?user_id=${user_id}&_method=put`,
			dataE
		);
	}
	MarkUnique(
		user_id,
		cl_id,
		transaction_id,
		validation_assignment,
		first_occurrence,
		notes
	) {
		return api.get(
			`/move-to-unique?user_id=${user_id}&cl_id=${cl_id}&transaction_id=${transaction_id}&validation_assignment=${validation_assignment}&first_occurrence=${first_occurrence}&notes=${notes}`
		);
	}
	MarkDuplicate(user_id, cl_id, transaction_id, notes) {
		return api.get(
			`/move-to-duplicate?user_id=${user_id}&cl_id=${cl_id}&transaction_id=${transaction_id}&notes=${notes}`
		);
	}
	// GetClientrules
	getClientsUnique(key) {
		return api.get("/unique-clients").then((res) => res.data.data);
	}

	getUniqueReport(
		user_id,
		format,
		client_id,
		file_search,
		start_dt,
		end_dt,
		date_type
	) {
		if ((start_dt != null) & (end_dt != null)) {
			return api.get(
				`/get-unique-report?user_id=${user_id}&format=${format}&client_id=${client_id}&file_search=${file_search}&start_dt=${start_dt}&end_dt=${end_dt}&date_type=${date_type}`
			);
		} else if (start_dt != null) {
			return api.get(
				`/get-unique-report?user_id=${user_id}&format=${format}&client_id=${client_id}&file_search=${file_search}&start_dt=${start_dt}&date_type=${date_type}`
			);
		} else if (start_dt != null) {
			return api.get(
				`/get-unique-report?user_id=${user_id}&format=${format}&client_id=${client_id}&file_search=${file_search}&end_dt=${end_dt}&date_type=${date_type}`
			);
		} else {
			return api.get(
				`/get-unique-report?user_id=${user_id}&format=${format}&client_id=${client_id}&file_search=${file_search}&date_type=${date_type}`
			);
		}
	}
	getDuplicateReport(
		user_id,
		format,
		client_id,
		file_search,
		start_dt,
		end_dt,
		date_type
	) {
		if ((start_dt != null) & (end_dt != null)) {
			return api.get(
				`/get-duplicate-report?user_id=${user_id}&format=${format}&client_id=${client_id}&file_search=${file_search}&start_dt=${start_dt}&end_dt=${end_dt}&date_type=${date_type}`
			);
		} else if (start_dt != null) {
			return api.get(
				`/get-duplicate-report?user_id=${user_id}&format=${format}&client_id=${client_id}&file_search=${file_search}&start_dt=${start_dt}&date_type=${date_type}`
			);
		} else if (start_dt != null) {
			return api.get(
				`/get-duplicate-report?user_id=${user_id}&format=${format}&client_id=${client_id}&file_search=${file_search}&end_dt=${end_dt}&date_type=${date_type}`
			);
		} else {
			return api.get(
				`/get-duplicate-report?user_id=${user_id}&format=${format}&client_id=${client_id}&file_search=${file_search}&date_type=${date_type}`
			);
		}
	}

	getFileNumber(key, draw = 0, client_id = -1) {
		var res = api
			.get(
				`/file-tracking?draw=${draw}&length=2147483648&client_id=${client_id}`
			)
			.then((res) => res.data.data);
		return res;
	}
	addClients(user_id, data) {
		return api.post(`/unique-clients?user_id=${user_id}`, data);
	}
	updateClients(client_id, user_id, dataE) {
		return api.post(
			`/unique-clients/${client_id}?user_id=${user_id}&_method=put`,
			dataE
		);
	}
	fileRollback(user_id, file_no, notes) {
		return api.post(
			`/roll-back?user_id=${user_id}&file_no=${file_no}&notes=${notes}`
		);
	}

	fetcher(url) {
		return api.get(url).then((res) => res.data.data);
	}
}

export default new UtilsService();
