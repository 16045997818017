import { createWebHistory, createRouter } from "vue-router";
import SignIn from "@/components/auth/SignIn.vue";

const authGuard = (to, from, next) => {
  var isAuthenticated = false;
  let user = JSON.parse(localStorage.getItem("user"));
  if (user.access_token) isAuthenticated = true;
  else isAuthenticated = false;
  if (isAuthenticated) next();
  else next("/login");
};

const routes = [
  { path: "/", component: SignIn, meta: { title: "Sign In" } },
  { path: "/login", component: SignIn, meta: { title: "Sign In" } },
  
 
  //Unique Transaction management
  {
    path: "/unique-management",
    component: () => import("@/components/UniqueID/Transaction.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transactions Management"},
  },
 
  {
    path: "/new-clients",
    component: () => import("@/components/UniqueID/ClientAdds.vue"),
    beforeEnter: authGuard,
    meta: { title: "New Clients" },
  },
  {
    path: "/transaction-logs",
    component: () => import("@/components/UniqueID/EditLogs.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transaction Logs" },
  },
  {
    path: "/transaction-upload",
    component: () => import("@/components/UniqueID/TransactionUpload.vue"),
    beforeEnter: authGuard,
    meta: { title: "Multiple transactions Upload" },
  },
  
  {
    path: "/unique-agencies",
    component: () => import("@/components/UniqueID/Agencies.vue"),
    beforeEnter: authGuard,
    meta: { title: "Agencies" },
  },
  {
    path: "/duplicate-transactions",
    component: () => import("@/components/UniqueID/DuplicateTransactions.vue"),
    beforeEnter: authGuard,
    meta: { title: "Duplicated Transactions" },
  },

  {
    path: "/clients-cl",
    component: () => import("@/components/UniqueID/cl1.vue"),
    beforeEnter: authGuard,
    meta: { title: "Confidence levels" },
  },
  {
    path: "/confidence-levels",
    component: () => import("@/components/UniqueID/cl2.vue"),
    beforeEnter: authGuard,
    meta: { title: "Confidence levels" },
  },
  {
    path: "/dashboard-uni",
    component: () => import("@/components/UniqueID/dashboard.vue"),
    beforeEnter: authGuard,
    meta: { title: "Dashboard" },
  },
  {
    path: "/business-rules",
    component: () => import("@/components/UniqueID/businessRules.vue"),
    beforeEnter: authGuard,
    meta: { title: "Business Rules" },
  },
  {
    path: "/complete-duplicate",
    component: () => import("@/components/UniqueID/CompleteDuplicate.vue"),
    beforeEnter: authGuard,
    meta: { title: "Complete Duplicates" },
  },
  
  {
    path: "/masterfile-upload",
    component: () => import("@/components/UniqueID/MasterfileUpload.vue"),
    beforeEnter: authGuard,
    meta: { title: "Vehicle Masterfile Upload" },
  },
  {
    path: "/file-track",
    component: () => import("@/components/UniqueID/Filetracking.vue"),
    beforeEnter: authGuard,
    meta: { title: "File Tracking Management" },
  },
  
  {
    path: "/glossary",
    component: () => import("@/components/UniqueID/glossary.vue"),
    beforeEnter: authGuard,
    meta: { title: "Glossary" },
  },
  {
    path: "/reports",
    component: () => import("@/components/UniqueID/reports.vue"),
    beforeEnter: authGuard,
    meta: { title: "Reports" },
  },

    { path: "/index.html", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkExactActiveClass: "active",
});

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title =
    "Innovative - " +
    (toRoute.meta && toRoute.meta.title ? toRoute.meta.title : "");
  next();
});

export default router;
