import AuthService from '../services/auth.service'

const user = JSON.parse((JSON.stringify)(localStorage.getItem('user')))
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null }

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user)
          return Promise.resolve(user)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      );
    },
    logout({ commit }) {
      AuthService.logout()
      commit('logout')
    },
    // register({ commit }, user) {
    //   return AuthService.register(user).then(
    //     response => {
    //       commit('registerSuccess')
    //       return Promise.resolve(response.data)
    //     },
    //     error => {
    //       commit('registerFailure')
    //       return Promise.reject(error)
    //     }
    //   )
    // },
    refreshToken({ commit }, accessToken) {
      commit('refreshToken', accessToken)
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true
      state.user = user
    },
    loginFailure(state) {
      state.status.loggedIn = false
      state.user = null
    },
    logout(state) {
      state.status.loggedIn = false
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false
    },
    registerFailure(state) {
      state.status.loggedIn = false
    },
    refreshToken(state, accessToken) {
      state.status.loggedIn = true
      state.user = { ...state.user, token: accessToken }
    }
  }
};
export const constants = {
  namespaced: true,
  state: {
    client_id: null,
  },
  actions: {
    setClientId({ commit }, client_id) {
      commit('commitClientId', client_id)
    }
  },
  mutations: {
    commitClientId(state, client_id) {
      state.client_id = client_id
    }
  }
};
